import Cascade1 from '../../../Media/Ruko BSD/Cascade/Cascade (1).webp'
import Cascade2 from '../../../Media/Ruko BSD/Cascade/Cascade (2).webp'
import Cascade3 from '../../../Media/Ruko BSD/Cascade/Cascade (3).webp'
import Cascade4 from '../../../Media/Ruko BSD/Cascade/Cascade (4).webp'
import Cascade5 from '../../../Media/Ruko BSD/Cascade/Cascade (5).webp'



const Cascadeimages =[
Cascade1,Cascade2,Cascade3,Cascade4,Cascade5]

export default Cascadeimages;
