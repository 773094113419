import Theloop1 from '../../../Media/Ruko BSD/The Loop/The Loop (1).webp'
import Theloop2 from '../../../Media/Ruko BSD/The Loop/The Loop (2).webp'
import Theloop3 from '../../../Media/Ruko BSD/The Loop/The Loop (3).webp'
import Theloop4 from '../../../Media/Ruko BSD/The Loop/The Loop (4).webp'
import Theloop5 from '../../../Media/Ruko BSD/The Loop/The Loop (5).webp'

const Theloopimages =[
Theloop1,Theloop2,Theloop3,Theloop4,Theloop5,
]

export default Theloopimages;
