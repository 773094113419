import React from "react";
import "./kavling.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Kavlingimages from "./Kavling";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
  faRoad,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281384969120&text=Halo%20Jason,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Produk%20Komersial%20BSD%20City%20https://bsdcity-commercial.id/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div id="kavling" className="surroundingarea">
      <div className="container-surrounding">
        <div id="surrounding" className="judul-surrounding">
          <h1>Kavling Commercial in BSD City</h1>
        </div>
        <div className="container-card">
          <div className="kartu">
            <Slider {...settings}>
              {Kavlingimages.map((image, index) => (
                <div>
                  <img
                    className="kartugambar1"
                    key={index}
                    src={image}
                    alt="athena"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="button">
          <div className="container-wa">
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
