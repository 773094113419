import Northpoint1 from '../../../Media/Ruko BSD/NTP/North Point Two (1).webp'
import Northpoint2 from '../../../Media/Ruko BSD/NTP/North Point Two (2).webp'
import Northpoint3 from '../../../Media/Ruko BSD/NTP/North Point Two (3).webp'
import Northpoint4 from '../../../Media/Ruko BSD/NTP/North Point Two (4).webp'
import Northpoint5 from '../../../Media/Ruko BSD/NTP/North Point Two (5).webp'
import Northpoint6 from '../../../Media/Ruko BSD/NTP/North Point Two (6).webp'



const Northpointimages =[
Northpoint1,Northpoint2,Northpoint3,Northpoint4,Northpoint5,Northpoint6
]

export default Northpointimages;
