import Northridge1 from '../../../Media/Ruko BSD/Northridge/Northridge (1).webp'
import Northridge2 from '../../../Media/Ruko BSD/Northridge/Northridge (2).webp'
import Northridge3 from '../../../Media/Ruko BSD/Northridge/Northridge (3).webp'
import Northridge4 from '../../../Media/Ruko BSD/Northridge/Northridge (4).webp'
import Northridge5 from '../../../Media/Ruko BSD/Northridge/Northridge (5).webp'
import Northridge6 from '../../../Media/Ruko BSD/Northridge/Northridge (6).webp'

const Northridgeimages =[
Northridge1,Northridge2,Northridge3,Northridge4,Northridge5,Northridge6
]

export default Northridgeimages;
