import Vanyapark1 from '../../../Media/Ruko BSD/Vanya/Vanya Park (1).webp'
import Vanyapark2 from '../../../Media/Ruko BSD/Vanya/Vanya Park (2).webp'
import Vanyapark3 from '../../../Media/Ruko BSD/Vanya/Vanya Park (3).webp'
import Vanyapark4 from '../../../Media/Ruko BSD/Vanya/Vanya Park (4).webp'
import Vanyapark5 from '../../../Media/Ruko BSD/Vanya/Vanya Park (5).webp'
import Vanyapark6 from '../../../Media/Ruko BSD/Vanya/Vanya Park (6).webp'

const Vanyaparkimages =[
Vanyapark1,Vanyapark2,Vanyapark3,Vanyapark4,Vanyapark5,Vanyapark6
]

export default Vanyaparkimages;
