import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar.jsx";
import Banner from "../Section/Banner/banner.jsx";
import About from "../Section/About/about.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Produk from "../Section/Produk/produk.jsx";
import Kavling from "../Section/Kavling BSD/kavling.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import ScrollToHashElement from "../Pages/ScrollToHashElement.js";
import tombol from "../Media/tombol.webp";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281384969120&text=Halo%20Jason,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Produk%20Komersial%20BSD%20City%20https://bsdcity-commercial.id/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Banner />
      <About />
      <Promo />
      <Produk />
      <Kavling />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
