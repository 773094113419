import Capitalcove1 from '../../../Media/Ruko BSD/Capital Cove/Capital Cove (1).webp'
import Capitalcove2 from '../../../Media/Ruko BSD/Capital Cove/Capital Cove (2).webp'
import Capitalcove3 from '../../../Media/Ruko BSD/Capital Cove/Capital Cove (3).webp'
import Capitalcove4 from '../../../Media/Ruko BSD/Capital Cove/Capital Cove (4).webp'




const Capitalcoveimages =[
Capitalcove1,Capitalcove2,Capitalcove3,Capitalcove4
]

export default Capitalcoveimages;
