import Kavling1 from '../../../src/Media/Kavling BSD/Kavling BSD (1).webp'
import Kavling2 from '../../../src/Media/Kavling BSD/Kavling BSD (2).webp'
import Kavling3 from '../../../src/Media/Kavling BSD/Kavling BSD (3).webp'
import Kavling4 from '../../../src/Media/Kavling BSD/Kavling BSD (4).webp'
import Kavling5 from '../../../src/Media/Kavling BSD/Kavling BSD (5).webp'
import Kavling6 from '../../../src/Media/Kavling BSD/Kavling BSD (6).webp'
import Kavling7 from '../../../src/Media/Kavling BSD/Kavling BSD (7).webp'
import Kavling8 from '../../../src/Media/Kavling BSD/Kavling BSD (8).webp'






const Kavlingimages =[
    Kavling1,Kavling2,Kavling3,Kavling4,Kavling5,Kavling6,Kavling7,Kavling8
]

export default Kavlingimages;