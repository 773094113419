import Xlane1 from '../../../Media/Ruko BSD/Xline/Xline (1).webp'
import Xlane2 from '../../../Media/Ruko BSD/Xline/Xline (2).webp'
import Xlane3 from '../../../Media/Ruko BSD/Xline/Xline (3).webp'
import Xlane4 from '../../../Media/Ruko BSD/Xline/Xline (4).webp'
import Xlane5 from '../../../Media/Ruko BSD/Xline/Xline (5).webp'

const Xlaneimages =[
Xlane1,Xlane2,Xlane3,Xlane4,Xlane5
]

export default Xlaneimages;
