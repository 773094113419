import Latinos1 from '../../../Media/Ruko BSD/Latinos/Latinos (1).webp'
import Latinos2 from '../../../Media/Ruko BSD/Latinos/Latinos (2).webp'
import Latinos3 from '../../../Media/Ruko BSD/Latinos/Latinos (3).webp'
import Latinos4 from '../../../Media/Ruko BSD/Latinos/Latinos (4).webp'
import Latinos5 from '../../../Media/Ruko BSD/Latinos/Latinos (5).webp'
import Latinos6 from '../../../Media/Ruko BSD/Latinos/Latinos (6).webp'
import Latinos7 from '../../../Media/Ruko BSD/Latinos/Latinos (7).webp'
import Latinos8 from '../../../Media/Ruko BSD/Latinos/Latinos (8).webp'
import Latinos9 from '../../../Media/Ruko BSD/Latinos/Latinos (9).webp'
import Latinos10 from '../../../Media/Ruko BSD/Latinos/Latinos (10).webp'
import Latinos11 from '../../../Media/Ruko BSD/Latinos/Latinos (11).webp'
import Latinos12 from '../../../Media/Ruko BSD/Latinos/Latinos (12).webp'
import Latinos13 from '../../../Media/Ruko BSD/Latinos/Latinos (13).webp'
import Latinos14 from '../../../Media/Ruko BSD/Latinos/Latinos (14).webp'
import Latinos15 from '../../../Media/Ruko BSD/Latinos/Latinos (15).webp'
import Latinos16 from '../../../Media/Ruko BSD/Latinos/Latinos (16).webp'
import Latinos17 from '../../../Media/Ruko BSD/Latinos/Latinos (17).webp'

const Latinosimages =[
Latinos1,Latinos2,Latinos3,Latinos4,Latinos5,Latinos6,Latinos7,Latinos8,Latinos9,Latinos10,Latinos11,Latinos12,Latinos13,Latinos14,Latinos15,Latinos16,Latinos17
]

export default Latinosimages;
