import React, {useState} from "react";
import "./footer.scss";
import logo from "../../Media/Untitled design (48).png";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FaArrowsUpToLine} from "react-icons/fa6";
import {faArrowsUpToLine} from "@fortawesome/free-solid-svg-icons/faArrowsUpToLine";
import {
  faArrowCircleUp,
  faArrowUp,
  faArrowUp19,
} from "@fortawesome/free-solid-svg-icons";
import {FaArrowUp} from "react-icons/fa";

const Footer = () => {
  const [isOpen] = useState(false);
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className={isOpen ? "menus active" : "menus"}>
          {" "}
          Website Map
          <ul>
            <li>
              <HashLink className="link" smooth to="#about">
                About
              </HashLink>
            </li>
            <li>
              <HashLink className="link" smooth to="#promo">
                Promo
              </HashLink>
            </li>
            <li>
              <HashLink className="link" smooth to="#rumah">
                Products
              </HashLink>
            </li>
            <li>
              <HashLink className="link" smooth to="#lokasi">
                Location
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Commercial BSD City</h2>
            <h3>
              Jl. Pahlawan Seribu, Lengkong Karya, Kec. Serpong Utara, Kota
              Tangerang Selatan, Banten 15310
            </h3>
            <h4>Contact us : +6281384969120</h4>
            <Link to="/privacy-policy">
              <div className="footer-contact">Privacy Policy</div>
            </Link>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <hr />
      <div className="container-bawah">
        <div className="rights">All rights reserved</div>
        <div className="judul-containerbawah">© Commercial BSD City</div>
        <div className="backtotop">
          <ul>
            <li>
              <HashLink className="link" smooth to="#navbar">
                Back to Top
              </HashLink>
            </li>
          </ul>
          <FontAwesomeIcon icon={faArrowCircleUp} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
