import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import aboutimg from "../../Media/Ruko BSD/Capital Cove/Capital Cove (1).webp";
import {
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {GiChecklist} from "react-icons/gi";
import {FaCheckDouble} from "react-icons/fa";

const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281384969120&text=Halo%20Jason,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Produk%20Komersial%20BSD%20City%20https://bsdcity-commercial.id/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <h1>Live Life with Everything You Need Just Around the Corners</h1>
        <div className="container-desk">
          <div className="desk">
            BSD City merupakan kawasan seluas 6.000 hektar yang dikeembangkan
            oleh developer Sinarmasland Tbk. Selain menawarkan Perumahan BSD
            City Juga menawarkan Area Commercial seperti Ruko, Office, Kavling,
            Serta Warehouse. Kami memiliki berbagai jenis tipe properti yang
            siap untuk mengembangkan bisnis anda.
          </div>
        </div>
        <div className="container-benefit">
          <div className="benefit-list">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Sourrounded by Many Residential
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;More Than 200.000+ Residents
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Easy Access
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Strategic Location
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;More Than 136 Residential
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Premium Facilities
              </span>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="container-wa">
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className="gambarabout">
        <img src={aboutimg} alt={aboutimg} />
      </div>
    </div>
  );
};

export default About;
