import Delrey1 from '../../../Media/Ruko BSD/Delrey/Delrey (1).webp'
import Delrey2 from '../../../Media/Ruko BSD/Delrey/Delrey (2).webp'
import Delrey3 from '../../../Media/Ruko BSD/Delrey/Delrey (3).webp'
import Delrey4 from '../../../Media/Ruko BSD/Delrey/Delrey (4).webp'
import Delrey5 from '../../../Media/Ruko BSD/Delrey/Delrey (5).webp'
import Delrey6 from '../../../Media/Ruko BSD/Delrey/Delrey (6).webp'
import Delrey7 from '../../../Media/Ruko BSD/Delrey/Delrey (7).webp'
import Delrey8 from '../../../Media/Ruko BSD/Delrey/Delrey (8).webp'
import Delrey9 from '../../../Media/Ruko BSD/Delrey/Delrey (9).webp'
import Delrey10 from '../../../Media/Ruko BSD/Delrey/Delrey (10).webp'
import Delrey11 from '../../../Media/Ruko BSD/Delrey/Delrey (11).webp'

const Delreyimages =[
Delrey1,Delrey2,Delrey3,Delrey4,Delrey5,Delrey6,Delrey7,Delrey8,Delrey9,Delrey10,Delrey11,
]

export default Delreyimages;
